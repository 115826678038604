import _ from 'underscore';
import React from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { jss } from 'react-jss';
import { createGenerateId } from '@bingads-webui-campaign-react/custom-jss-config';
import { SmartPageRuntime } from '@bingads-webui-campaign-react/smart-page-runtime-component';
import { modelToSiteConfig } from '@bingads-webui-campaign-react/smart-component-util';
import {
  getInstrumentationKeyByEnv,
  SmartPageUrlSuffix,
} from '@bingads-webui-campaign/smart-page-url-suffix-provider';
import {
  DataServiceContext,
  AppInsightsContext,
  InstrumentationServiceContext,
  getInstrumentation,
  getInstrumentationService,
} from '@bingads-webui-campaign-react/smart-page-context';
import { I18nContext } from '@bingads-webui-react/i18n-context';

import i18n from './main.l10n';

jss.setup({
  createGenerateId,
});

class App extends React.PureComponent {
  render() {
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: getInstrumentationKeyByEnv(document.domain),
        enableDebug: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        loggingLevelConsole: 2, // Everything (errors & warnings)
        loggingLevelTelemetry: 2, // Everything (errors & warnings)
        correlationHeaderExcludedDomains: [
          'vimeo.com',
          'browser.pipe.aria.microsoft.com',
        ],
      },
    });

    if (appInsights) {
      appInsights.loadAppInsights();
      appInsights.trackPageView();
    }

    const domain = window.location.hostname;
    // For custom domain mapping, we always consider it as PROD environment
    // as we will have really little custom domain case in SI and never in CI
    const isProdRuntime = !domain.includes(SmartPageUrlSuffix.SI)
      && !domain.includes(SmartPageUrlSuffix.CI)
      && !domain.includes(SmartPageUrlSuffix.BETASI);

    const instrumentation = getInstrumentation({
      serverContext: {},
      isProdRuntime,
    });
    const instrumentationService = getInstrumentationService({
      instrumentation,
      serverContext: {},
    });

    const dataServiceContext = {
      DynamicPermissions: (window.PILOTS || {}),
      Permissions: {
        IsM365PremiumUser: !!(_.isObject(window.staticConfig)
          && window.staticConfig.IsM365PremiumUser),
      },
    };
    const siteInfo = window.smartPageinfo
      ? modelToSiteConfig(window.smartPageinfo, dataServiceContext)
      : null;

    return (
      <I18nContext.Provider value={i18n}>
        <DataServiceContext.Provider value={dataServiceContext}>
          <AppInsightsContext.Provider value={appInsights}>
            <InstrumentationServiceContext.Provider value={instrumentationService}>
              <SmartPageRuntime
                siteInfo={siteInfo}
                isRuntime
              />
            </InstrumentationServiceContext.Provider>
          </AppInsightsContext.Provider>
        </DataServiceContext.Provider>
      </I18nContext.Provider>
    );
  }
}

export default App;
