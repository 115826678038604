/* eslint-disable import/first */
import './public-path'; // Don't try to re-order this, it needs to be imported before any other modules

import React from 'react';
import ReactDOM from 'react-dom';
import { initializeIcons } from '@fluentui/react';
import { PageReady } from '@bingads-webui/page-ready';
import { getCldrLocale, setupI18nModel } from '@bingads-webui/i18n-model-setup';
import { SmartPageUrlSuffix } from '@bingads-webui-campaign/smart-page-url-suffix-provider';
import { I18nModel } from '@bingads-webui-universal/i18n-model';

import './index.less';

const { WebsiteSourceData = {} } = window.smartPageinfo || {};

const locale = WebsiteSourceData.Localization || document.documentElement.lang || 'en-us';

const cldrLocale = getCldrLocale(locale);

const domain = window.location.hostname;
const isProdRuntime = domain.includes(SmartPageUrlSuffix.PROD) || domain.includes(SmartPageUrlSuffix.BETA);
const defaultFailFast = isProdRuntime ? 'SAFE' : I18nModel.defaultFailFast;
I18nModel.setDefaultFailFast(defaultFailFast);

import(/* webpackChunkName: "cldr-[request]" */`@bingads-webui-universal/i18n-cldr-data-modern/locales/${cldrLocale}.json`)
  .then(setupI18nModel(locale))
  .then(() => {
    // Initialize UI fabric icons
    initializeIcons();
    PageReady.pageIsReady();

    // eslint-disable-next-line global-require
    const AppRoot = require('./app').default;

    ReactDOM.render(<AppRoot />, document.getElementById('root'));
  });
